import { useEffect } from "react";
import { Link, NavLink, useOutletContext } from "react-router-dom";

function AsideBar({ reports }) {
  // const context = useOutletContext();
  // const reports = context?.reports || [];
  // const reports = JSON.parse(localStorage.getItem("him_reports")) || [];
  const moreLink = (env, id) => {
    if (!env.target.matches(".active")) {
      document.querySelectorAll(".nav-toggle").forEach((e) => {
        e.classList.remove("active");
      });
      document.querySelectorAll(".navmore-link").forEach((e) => {
        e.classList.remove("active");
      });
      env.target.classList.add("active");
      document.getElementById(id).classList.add("active");
    } else {
      env.target.classList.remove("active");
      document.getElementById(id).classList.remove("active");
    }
  };

  const menuToggle = () => {
    document.body.classList.toggle("shrink-menu");
  };
  return (
    <>
      <aside>
        <div className="dashboard-logo">
          <Link to="/">
            <img src={`${window.origin}/images/logo.png`} alt="logo" />
          </Link>
          <button type="button" onClick={() => menuToggle()}>
            <svg className="icon">
              <use href="#icon_menu"></use>
            </svg>
          </button>
        </div>
        <ul className="d-flex flex-column justify-content-between ">
          <div>
            <li>
              <NavLink to="/">
                <span>
                  <svg className="icon">
                    <use href="#icon_code_sheet"></use>
                  </svg>
                </span>
                <strong>All Code Sheet</strong>
              </NavLink>
            </li>
            <li>
              <NavLink to="/daily_transaction_head_office">
                <span>
                  <svg className="icon">
                    <use href="#icon_daily_transaction_head_office"></use>
                  </svg>
                </span>
                <strong>Daily transaction head office</strong>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/article_gst">
                <span>
                  <svg className="icon">
                    <use href="#icon_article_gst"></use>
                  </svg>
                </span>
                <strong>Article & GST</strong>
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink to="/head_office_cash_receiving">
                <span>
                  <svg className="icon">
                    <use href="#icon_head_office_cash_receiving"></use>
                  </svg>
                </span>
                <strong>Head office cash receiving</strong>
              </NavLink>
            </li>
            <li>
              <NavLink to="/head_office_cash_payment">
                <span>
                  <svg className="icon">
                    <use href="#icon_head_office_cash_payment"></use>
                  </svg>
                </span>
                <strong>Head office cash payment</strong>
              </NavLink>
            </li>
            <li>
              <NavLink to="/head_office_cash_approval">
                <span>
                  <svg className="icon">
                    <use href="#icon_head_office_cash_approval"></use>
                  </svg>
                </span>
                <strong>Head office cash approval</strong>
              </NavLink>
            </li> */}

            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more2")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_cash_tracking"></use>
                  </svg>
                </span>
                <strong>Cash Tracking</strong>
              </div>
              <div className="navmore-link" id="more2">
                <NavLink to="/cash_track/cash_track_office">
                  Cash Track Head Office
                </NavLink>
                <NavLink to="/cash_track/daily_cash_transactions">
                  Daily Cash Transaction
                </NavLink>
              </div>
            </li>
            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more3")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_home_expenses"></use>
                  </svg>
                </span>
                <strong>Home Expenses</strong>
              </div>
              <div className="navmore-link" id="more3">
                <NavLink to="/home_expenses/site_consumption">
                  Site Consumption
                </NavLink>
                <NavLink to="/home_expenses/item_master">
                  Item Bill Adjusted
                </NavLink>
              </div>
            </li>
            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more4")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_discrepancy"></use>
                  </svg>
                </span>
                <strong>Discrepancy</strong>
              </div>
              <div className="navmore-link" id="more4">
                <NavLink to="/discrepancy/purchase">Purchase</NavLink>
              </div>
            </li>
            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more5")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_conclusion"></use>
                  </svg>
                </span>
                <strong>Conclusion</strong>
              </div>
              <div className="navmore-link" id="more5">
                <NavLink to="/conclusion/purchase">Purchase</NavLink>
              </div>
            </li>
            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more6")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_all_bill"></use>
                  </svg>
                </span>
                <strong>Bills</strong>
              </div>
              <div className="navmore-link" id="more6">
                <NavLink to="/bills/all_bill">All Bills</NavLink>
                <NavLink to="/bills/paid_bill">Paid Bills</NavLink>
              </div>
            </li>
            {/* <li>
              <NavLink to="/all_bill">
                <span>
                  <svg className="icon">
                    <use href="#icon_all_bill"></use>
                  </svg>
                </span>
                <strong>All Bills</strong>
              </NavLink>
            </li> */}
            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more1")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_report"></use>
                  </svg>
                </span>
                <strong>Reports</strong>
              </div>
              <div className="navmore-link" id="more1">
                {reports
                  ?.filter((report) => report?.web_status)
                  ?.map((report, index) => {
                    return (
                      <NavLink
                        key={report?.report_code}
                        to={`/report/${report?.report_code}`}
                      >
                        {report?.report_name}
                      </NavLink>
                    );
                  })}
                {/* <NavLink to="/uploadbank">Bank file</NavLink>
              <NavLink to="/uploadgst">GST file</NavLink>
              <NavLink to="/estimate">Estimate</NavLink> */}
              </div>
            </li>
            {/* <li>
              <NavLink to="/contractor_supplier_rate_approved">
                <span>
                  <svg className="icon">
                    <use href="#icon_vendor_quotes"></use>
                  </svg>
                </span>
                <strong>Contractor/Supplier Rate Approved</strong>
              </NavLink>
            </li> */}
            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more7")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_sheet"></use>
                  </svg>
                </span>
                <strong>Engineering Sheet</strong>
              </div>
              <div className="navmore-link" id="more7">
                <NavLink to="/engineering-sheet/consolidated">
                  Consolidated
                </NavLink>
                <NavLink to="/engineering-sheet/balance-to-pay">
                  Balance to pay
                </NavLink>
                <NavLink to="/engineering-sheet/in-stock">In stock</NavLink>
                <NavLink to="/engineering-sheet/to-be-refunded">
                  To be refunded
                </NavLink>
              </div>
            </li>
            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more8")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_vendor_quotes"></use>
                  </svg>
                </span>
                <strong>Contractor/Supplier Rate Approved</strong>
              </div>
              <div className="navmore-link" id="more8">
                <NavLink to="/contractor_supplier_rate_approved/quote_to_approve">
                  Quote to approve
                </NavLink>
                <NavLink to="/contractor_supplier_rate_approved/approved_quote">
                  Approved quote
                </NavLink>
              </div>
            </li>
          </div>
          <div>
            <li>
              <NavLink
                onClick={() => {
                  localStorage.removeItem("him_user_token");
                  localStorage.removeItem("him_user_data");
                }}
                to="/login"
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_logout"></use>
                  </svg>
                </span>
                <strong>Logout</strong>
              </NavLink>
            </li>
          </div>
        </ul>
      </aside>
    </>
  );
}

export default AsideBar;
