import { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import AddArticleGstModal from "../components/AddArticleGstModal";
import * as XLSX from "xlsx";

function ItemMaster() {
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [isUpdatingItem, setIsUpdatingItem] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const handleCloseAddItem = () => setShowAddItem(false);
  const handleShowAddItem = (data) => setShowAddItem(data);

  const navigate = useNavigate();
  const componentRef = useRef();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  async function getRows() {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getitemslist`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      setRows(response?.data?.data);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function printOrExportData(isPrint) {
    try {
      if (isPrint) {
        setIsPrinting(true);
      } else {
        setIsExporting(true);
      }
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getitemslist`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      const tableData = response?.data?.data;

      if (isPrint) {
        // Open a new window
        const printWindow = window.open("", "");

        // Generate HTML content for the new window
        const tableHtml = `
        <html>
          <head>
            <title>Print</title>
            <style>
              body {
                font-family: Arial, sans-serif;
              }
              .table-wrapper {
                width: 100%;
                border-collapse: collapse;
              }
              .table-wrapper th, .table-wrapper td {
                border: 1px solid #000;
                padding: 8px;
                text-align: left;
              }
              .table-wrapper th {
                background-color: #f2f2f2;
              }
              @media print {
                @page {
                  size: A4 landscape;
                  margin: 15mm;
                }
              }
            </style>
          </head>
          <body>
            <h1>
              Item Bill Adjusted
            </h1>
            <table class="table-wrapper">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Item</th>
                  <th>Type</th>
                  <th>GST Ratio</th>
                </tr>
              </thead>
              <tbody>
                               ${tableData
                                 .map(
                                   (row, index) => `
                      <tr>
                        <td>${index + 1}</td>
                        <td>${row?.item_name || ""}</td>
                        <td>${
                          row?.type == 1
                            ? "Personal"
                            : row?.type == 2
                            ? "Firm"
                            : ""
                        }</td>
                        <td>${row?.gst_percent || ""}</td>
                      </tr>
                    `
                                 )
                                 .join("")}
              </tbody>
            </table>
            <script>
              window.onload = function() {
                window.print();
                window.onafterprint = window.close;
              };
            </script>
          </body>
        </html>
      `;

        // Write content to the new window
        printWindow.document.open();
        printWindow.document.write(tableHtml);
        printWindow.document.close();
      } else {
        const exportData = tableData.map((item, index) => {
          const data = {
            "Sr.No.": parseInt(page) * limit + index + 1,
            Item: item?.item_name || "",
            Type: item?.type == 1 ? "Personal" : item?.type == 2 ? "Firm" : "",
            "GST Ratio": item?.gst_percent || "",
          };

          return data;
        });
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `daily_transaction_head_office.xlsx`);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });

      if (error?.response?.status == 403) {
        console.log("error", error);
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      if (isPrint) {
        setIsPrinting(false);
      } else {
        setIsExporting(false);
      }
    }
  }

  async function handleAddEditItem(
    event,
    article,
    gst_ratio,
    handleCloseCallback,
    type
  ) {
    event && event?.preventDefault();

    setIsUpdatingItem(true);
    try {
      const token = localStorage.getItem("him_user_token");

      const data = {
        // item_id: showAddItem?.item_id,
        // article,
        // gst_ratio,
        data: [
          {
            item_id: showAddItem?.item_id,
            item_name: article,
            gst_percent: gst_ratio,
            type,
          },
        ],
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/updateitems`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        data: data,
      });

      if (response?.data?.success) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.msg);
        setTimeout(() => {
          handleCloseSuccess();
        }, 5000);
        handleCloseCallback ? handleCloseCallback() : handleCloseAddItem();
      } else {
        setError({ title: "Error", err: response?.data?.msg });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      getRows();
      setIsUpdatingItem(false);
    }
  }
  useEffect(() => {
    getRows();
  }, []);
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">Item Bill Adjusted</h1>
          <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={() => handleShowAddItem(true)}
            >
              Add
            </button>
            <button
              type="button"
              className="button button-brown mr-30 ml-30"
              onClick={() => printOrExportData(true)}
              disabled={!rows.length || isPrinting}
            >
              {isPrinting ? <Loader /> : "Print"}
            </button>
          </div>
        </div>

        <div ref={componentRef} className="tbl-scroller">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="srtd"> Sr. No. </th>
                <th> Item </th>
                <th> Type </th>
                <th> GST Ratio </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="tbody-gap">
              <tr>
                <td colSpan="13"></td>
              </tr>
            </tbody>

            {!isGettingRows && (
              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    <td>{index + 1}</td>
                    <td>{row?.item_name}</td>
                    <td>
                      {/* {row?.type == 1
                        ? "Personal"
                        : row?.type == 2
                        ? "Firm"
                        : ""} */}
                      {
                        // split the type string by comma and map over it
                        row?.type
                          ?.toString()
                          ?.replaceAll("1", "Personal")
                          ?.replaceAll("2", "Firm")
                      }
                    </td>
                    <td>{row?.gst_percent}</td>
                    <td>
                      <button
                        onClick={() => handleShowAddItem(row)}
                        className="button  px-2"
                        type="button"
                      >
                        <svg className="icon fs-22">
                          <use href="#icon_edit"></use>
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
        </div>
      </div>

      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <AddArticleGstModal
        showAddItem={showAddItem}
        isUpdatingItem={isUpdatingItem}
        handleAddEditItems={handleAddEditItem}
        handleCloseAddItem={handleCloseAddItem}
      />
    </>
  );
}

export default ItemMaster;
