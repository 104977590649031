import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import Loader from "../../components/Loader";
import ErrorModal from "../../components/ErrorModal";
import { SuccessModal } from "../../components/SuccessModal";
import moment from "moment";
import Select from "react-select";
import { DatePicker } from "antd";
import { useReactToPrint } from "react-to-print";
const { RangePicker } = DatePicker;
function HardCopyOfBillReceivings() {
  // const { report } = useParams();
  const context = useOutletContext();
  const reports = context?.reports || [];
  const componentRef = useRef();

  // const reports = JSON.parse(localStorage.getItem("him_reports")) || [];
  let currentReport =
    reports.find((r) => r.report_code === "hard_copy_of_bill_receivings") || {};
  let columns = [
    {
      name: "Date Of Bill Raised",
      code: "date_off_bill_raised",
      format: (value) => moment(value).format("DD MMM YYYY"),
    },
    {
      name: "From Vendor",
      code: "from_vendor",
    },
    {
      name: "To Firm",
      code: "to_firm",
    },
    {
      name: "Bill Amount",
      code: "bill_amount",
    },
    {
      name: "Invoice No",
      code: "invoice_number",
    },
    {
      name: "Article",
      code: "article",
    },
    {
      name: "GST Ratio",
      code: "gst_ratio",
    },
    {
      name: "Purpose Of Payment",
      // name: "Ledger Head",
      code: "ledger_head",
    },
    {
      name: "Pending Amount Invoices",
      code: "pending_amount_invoices",
    },
    {
      name: "Vendor Contact Person",
      code: "vendor_contact_person",
    },
    {
      name: "Remarks",
      code: "remarks",
    },
    {
      name: "Emp. Name",
      code: "employee_name",
    },
    {
      name: "Emp. Code",
      code: "employee_code",
    },
  ];
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(40);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isGettingRows, setIsGettingRows] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();

  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }

  const [selectedUser, setSelectedUser] = useState();
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [userList, setUserList] = useState([]);
  const navigate = useNavigate();

  async function getRows(selectedUser, selectedFromDate, selectedToDate) {
    try {
      setIsGettingRows(true);
      const token = localStorage.getItem("him_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/getreportdata`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
        params: {
          report_code:
            currentReport?.report_code || "hard_copy_of_bill_receivings",
          user_code: selectedUser?.value || null,
          from_date: selectedFromDate
            ? selectedFromDate?.format("YYYY-MM-DD")
            : null,
          to_date: selectedToDate ? selectedToDate?.format("YYYY-MM-DD") : null,
          page: parseInt(page),
          limit: parseInt(limit),
          paginate: true,
        },
      });

      setRows(response?.data?.data || []);
      setTotalCount(response?.data?.count);
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsGettingRows(false);
    }
  }

  async function getAllEmployees() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/getemployees`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        setUserList(response.data?.data);
      } else {
        setUserList([]);
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  const exportToExcel = () => {
    const tableData = rows.map((item, index) => {
      const data = {
        // "Sr.No.": index + 1,
        "Sr.No.": parseInt(page) * limit + index + 1,
      };
      columns.forEach((column) => {
        data[column.name] = column?.format
          ? column?.format(item[column.code])
          : item[column.code];
      });
      return data;
    });
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Export to Excel file
    XLSX.writeFile(workbook, `${"hard_copy_of_bill_receivings"}.xlsx`);
  };

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: `
    @page {
      size: A4 landscape;
    }
    @media print {
      body {
        -webkit-print-color-adjust: exact;
      }
      .tbl-scroller {
        overflow: visible !important;
      }
      .table-wrapper {
        width: 100%;
        transform: scale(0.75);
        transform-origin: top left;
      }
      * {
        color: black !important;
      }
    }
  `,
  });

  useEffect(() => {
    getRows(selectedUser, selectedFromDate, selectedToDate);
  }, []);
  useEffect(() => {
    getAllEmployees();
  }, []);
  return (
    <>
      <div className="inner-box">
        <div className="d-flex">
          <h1 className="heading">{currentReport?.report_name || ""}</h1>
          {/* <div className="ms-auto">
            <button
              className="button button-brown px-4"
              type="button"
              onClick={handleShow}
            >
              Upload
            </button>
          </div> */}
        </div>

        {/* new add */}
        <div className="mb-20 d-flex gray justify-content-between">
          <div className="d-flex">
            <div className="w-45 me-4">
              <label htmlFor="" className="mb-10 d-block">
                Choose User
              </label>
              {/* <select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                className="input-box"
                id="cars"
                name="cars"
              >
                <option value="">All User</option>
                {userList.map((user) => (
                  <option key={user?.id} value={user?.code}>
                    {user?.code + "-" + user?.name}
                  </option>
                ))}
              </select> */}
              <Select
                // className="input-box"
                value={selectedUser}
                onChange={(option) => {
                  setSelectedUser(option);
                  if (page == 0) {
                    getRows(option, selectedFromDate, selectedToDate);
                  } else {
                    setPage(0);
                  }
                }}
                options={userList.map((user) => ({
                  value: user?.code,
                  label: user?.code + "-" + user?.name,
                }))}
                // placeholder="Select To Code"
                isSearchable
                isClearable
              />
            </div>
            {/* <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                From Date
              </label>
              <input
                value={selectedFromDate}
                onChange={(e) => setSelectedFromDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div>
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                To Date
              </label>
              <input
                value={selectedToDate}
                onChange={(e) => setSelectedToDate(e.target.value)}
                type="date"
                className="input-box gray"
              />
            </div> */}
            <div className="w-45 mr-30">
              <label htmlFor="" className="mb-10 d-block">
                Date
              </label>
              <RangePicker
                className="input-box gray"
                value={[selectedFromDate, selectedToDate]}
                format={"DD MMM YYYY"}
                onChange={(dates) => {
                  setSelectedFromDate(dates?.length ? dates[0] : "");
                  setSelectedToDate(dates?.length ? dates[1] : "");
                  if (page == 0) {
                    getRows(
                      selectedUser,
                      dates?.length ? dates[0] : "",
                      dates?.length ? dates[1] : ""
                    );
                  } else {
                    setPage(0);
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              alignSelf: "flex-end",
            }}
          >
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={exportToExcel}
              disabled={!rows.length}
            >
              Export
            </button>
            <button
              type="button"
              className="button button-brown mr-30"
              onClick={handlePrint}
              disabled={!rows.length}
            >
              Print
            </button>
          </div>
        </div>
        {/* <hr className="mr-30 my-10"></hr>
        <div className="d-flex jc-between my-10">
          <div
            style={{
              visibility: "hidden",
            }}
          >
            <button type="button" className="button button-brown mr-30">
              View grid
            </button>
            <button type="button" className="button button-brown mr-30">
              Save
            </button>
            <button className="btn-undo" type="button">
              <svg className="icon fs-30 pt-10">
                <use href="#icon_undo"></use>
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="button button-brown mr-30"
              // onClick={handleShowAddCred}
            >
              Export
            </button>
          </div>
        </div> */}
        <div ref={componentRef} className="tbl-scroller">
          {!isGettingRows && rows?.length ? (
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="srtd">Sr.No.</th>
                  {columns.map((column) => (
                    <th key={column.code}>{column.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="tbody-gap">
                <tr>
                  <td colSpan="13"></td>
                </tr>
              </tbody>

              <tbody>
                {rows.map((row, index) => (
                  <tr key={row?.id}>
                    {/* <td>{index + 1}</td> */}
                    <td>{parseInt(page) * limit + index + 1}</td>
                    {columns.map((column) => (
                      <td key={column.code}>
                        {column?.format
                          ? column?.format(row[column.code])
                          : row[column.code]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )}
          {isGettingRows && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader color={"#aa741f"} />
            </div>
          )}
          {/* {!rows.length && !isGettingRows && (
            <div className="d-flex jc-center">
              <span>No data found</span>
            </div>
          )} */}
        </div>

        <form className="d-flex jc-between pt-30">
          <select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
            name="cars"
            id="cars"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
            <option value={90}>90</option>
            <option value={100}>100</option>
          </select>
          <div className="pagination">
            <button
              disabled={parseInt(page) === 0}
              onClick={() => setPage(parseInt(page) - 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_leftarrow"></use>
              </svg>
            </button>
            <span>
              Page {parseInt(page) + 1} of {Math.ceil(totalCount / limit)}
            </span>
            <button
              disabled={parseInt(page) + 1 === Math.ceil(totalCount / limit)}
              onClick={() => setPage(parseInt(page) + 1)}
              type="button"
              className="page-leftright"
            >
              <svg className="icon fs-24">
                <use href="#icon_rightarrow"></use>
              </svg>
            </button>
          </div>
        </form>
      </div>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default HardCopyOfBillReceivings;
