import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";

function AddHeadOfficeTransaction({
  showAddCred,
  handleCloseAddCred,
  handleAddEditMobileCreds,
  isUpdatingCreds,
  label,
  payment_type,
}) {
  // "trans_date":"2024-10-10",
  //   "payment_type":"1",
  //   "from_code":"E001",
  //   "to_code":"V001",
  //   "amount":"1000"

  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [fromCode, setFromCode] = useState();
  const [fromBank, setFromBank] = useState();
  const [toCode, setToCode] = useState();
  const [toBank, setToBank] = useState();
  const [cheque, setCheque] = useState();
  const [recBy, setRecBy] = useState();
  const [site, setSite] = useState();
  const [transType, setTransType] = useState();
  const [paymentType, setPaymentType] = useState();
  const [ledgerHead, setLedgerHead] = useState();
  const [amount, setAmount] = useState("");
  const [rectification, setRectification] = useState();
  const [userList, setUserList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [fromBankList, setFromBankList] = useState([]);
  const [toBankList, setToBankList] = useState([]);
  const [ledgerList, setLedgerList] = useState([]);
  const handleClose = () => {
    setDate("");
    setFromCode("");
    setToCode("");
    setAmount("");
    setRecBy("");
    setTransType("");
    setLedgerHead("");
    setSite("");
    setPaymentType("");
    setFromBank("");
    setToBank("");
    setCheque("");
    handleCloseAddCred();
  };

  async function getContractors(type) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himreport/getcontractorsbyledgermaster`,
        method: "GET",
        params: {
          ledger_master: type || "",
        },
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        // setUserList(
        return response.data?.data || [];
        // [
        //   // {
        //   //   code: "E001",
        //   //   name: "E001",
        //   //   id: 1,
        //   // },
        //   // {
        //   //   code: "V001",
        //   //   name: "V001",
        //   //   id: 2,
        //   // },
        // ]
        // );
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getLedgerList() {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himallcodesheet/payment_purpose`,
        method: "GET",

        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        // setUserList(
        return response.data?.data || [];
        // [
        //   // {
        //   //   code: "E001",
        //   //   name: "E001",
        //   //   id: 1,
        //   // },
        //   // {
        //   //   code: "V001",
        //   //   name: "V001",
        //   //   id: 2,
        //   // },
        // ]
        // );
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }
  async function getBanks(firm) {
    try {
      const token = localStorage.getItem("him_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/himapi/getfirmbank/${firm}`,
        method: "GET",
        headers: {
          jwt_token: token,
        },
      });

      if (response.status === 200) {
        // setUserList(
        return response.data?.data || [];
        // [
        //   // {
        //   //   code: "E001",
        //   //   name: "E001",
        //   //   id: 1,
        //   // },
        //   // {
        //   //   code: "V001",
        //   //   name: "V001",
        //   //   id: 2,
        //   // },
        // ]
        // );
      } else {
        return [];
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        localStorage.removeItem("him_user_token");
        localStorage.removeItem("him_user_data");
        navigate("/login");
        return [];
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  }

  useEffect(() => {
    if (showAddCred?.id) {
      setDate(showAddCred?.date);
      let from = userList.find((user) => user?.code == showAddCred?.from_code);
      let to = userList.find((user) => user?.code == showAddCred?.to_code);
      setFromCode(
        from
          ? {
              label: from?.code + "-" + from?.name,
              value: from?.code,
            }
          : null
      );
      setToCode(
        to
          ? {
              label: to?.code + "-" + to?.name,
              value: to?.code,
            }
          : null
      );
      console.log(payment_type, showAddCred);
      setAmount(
        payment_type == 1
          ? showAddCred?.debit_amount
          : showAddCred?.credit_amount
      );
      setRecBy(
        showAddCred?.received_by_data
          ? {
              label:
                showAddCred?.received_by_data?.code +
                "-" +
                showAddCred?.received_by_data?.name,
              value: showAddCred?.received_by_data?.code,
            }
          : null
      );
      setTransType(showAddCred?.trans_type ? showAddCred?.trans_type : "");

      // setLedgerHead(showAddCred?.ledger_head ? showAddCred?.ledger_head : "");
      setLedgerHead(
        showAddCred?.ledger_head
          ? {
              label: showAddCred?.ledger_head,
              value: showAddCred?.ledger_head,
            }
          : null
      );
      setRectification(
        showAddCred?.rectification
          ? {
              label: showAddCred?.rectification,
              value: showAddCred?.rectification,
            }
          : null
      );
      setSite(
        showAddCred?.contact_person
          ? {
              label: showAddCred?.contact_person,
              value: showAddCred?.contact_person,
            }
          : null
      );
      setPaymentType(showAddCred?.payment_type);
      setCheque(showAddCred?.cheque);
      setFromBank(
        showAddCred?.frombank
          ? {
              label:
                showAddCred?.frombank?.code + "-" + showAddCred?.frombank?.name,
              value: showAddCred?.frombank?.code,
            }
          : {
              label: showAddCred?.from_bank_code,
              value: showAddCred?.from_bank_code,
            }
      );
      setToBank(
        showAddCred?.tobank
          ? {
              label:
                showAddCred?.tobank?.code + "-" + showAddCred?.tobank?.name,
              value: showAddCred?.tobank?.code,
            }
          : {
              label: showAddCred?.to_bank_code,
              value: showAddCred?.to_bank_code,
            }
      );
    }
  }, [showAddCred]);

  useEffect(() => {
    (async () => {
      let users = await getContractors("Firm,Vendor,Employee,Bank,Head Office");
      setUserList(users);
      let employeeList = await getContractors("Employee,Office");
      setEmployeeList(employeeList);
      let bankList = await getContractors("Bank");
      // setBankList(bankList);
      let ledgerList = await getLedgerList();
      setLedgerList(ledgerList);
      if (showAddCred?.id) {
        let from = users.find((user) => user?.code == showAddCred?.from_code);
        let to = users.find((user) => user?.code == showAddCred?.to_code);
        let site = employeeList.find(
          (user) => user?.code == showAddCred?.contact_person
        );
        let frombank = bankList.find(
          (user) => user?.code == showAddCred?.from_bank_code
        );
        let tobank = bankList.find(
          (user) => user?.code == showAddCred?.to_bank_code
        );
        let ledger = ledgerList.find(
          (user) => user?.id == showAddCred?.ledger_head
        );
        let rectification = ledgerList.find(
          (user) => user?.id == showAddCred?.rectification
        );
        setFromCode(
          from
            ? {
                label: from?.code + "-" + from?.name,
                value: from?.code,
              }
            : null
        );
        setToCode(
          to
            ? {
                label: to?.code + "-" + to?.name,
                value: to?.code,
              }
            : null
        );
        setSite(
          site
            ? {
                label: site?.code + "-" + site?.name,
                value: site?.code,
              }
            : {
                label: showAddCred?.contact_person,
                value: showAddCred?.contact_person,
              }
        );
        setFromBank(
          frombank
            ? {
                label: frombank?.code + "-" + frombank?.name,
                value: frombank?.code,
              }
            : {
                label: showAddCred?.from_bank_code,
                value: showAddCred?.from_bank_code,
              }
        );
        setToBank(
          tobank
            ? {
                label: tobank?.code + "-" + tobank?.name,
                value: tobank?.code,
              }
            : {
                label: showAddCred?.to_bank_code,
                value: showAddCred?.to_bank_code,
              }
        );
        setLedgerHead(
          ledger
            ? {
                label: ledger?.name,
                value: ledger?.id,
              }
            : {
                label: showAddCred?.ledger_head,
                value: showAddCred?.ledger_head,
              }
        );
        setRectification(
          rectification
            ? {
                label: rectification?.name,
                value: rectification?.id,
              }
            : {
                label: showAddCred?.rectification,
                value: showAddCred?.rectification,
              }
        );
      }
    })();
  }, []);

  return (
    <Modal show={showAddCred} onHide={handleClose} animation={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddEditMobileCreds(
              e,
              date,
              paymentType,
              fromCode?.value || null,
              toCode?.value || null,
              amount,
              handleClose,
              recBy?.value || null,
              transType,
              ledgerHead?.value || null,
              site?.value || null,
              showAddCred?.id,
              fromBank?.value || null,
              toBank?.value || null,
              cheque,
              rectification?.value || null
            )
          }
        >
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Date
            </label>
            <input
              required
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              className="input-box"
              placeholder="Select Date"
              onFocus={(e) => e.target.showPicker()}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              From Code
            </label>
            {/* select dropdown */}
            {/* <select
              value={fromCode}
              onChange={(e) => setFromCode(e.target.value)}
              className="input-box"
              id="cars"
              name="cars"
            >
              <option value="">Select From Code</option>
              {userList.map((user) => (
                <option key={user?.id} value={user?.code}>
                  {user?.code + "-" + user?.name}
                </option>
              ))}
            </select> */}
            <Select
              // className="input-box"
              value={fromCode}
              required
              onChange={async (option) => {
                setFromCode(option);
                setFromBank("");
                if (!option?.value) return;
                let bankList = await getBanks(option?.value);
                setFromBankList(bankList);
              }}
              options={userList.map((user) => ({
                value: user?.code,
                label: user?.code + "-" + user?.name,
              }))}
              placeholder="Select From Code"
              isSearchable
              isClearable
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              From Bank
            </label>

            <Select
              // className="input-box"
              value={fromBank}
              disabled={!fromCode?.value}
              required
              onChange={setFromBank}
              options={fromBankList.map((user) => ({
                value: user?.code,
                label: user?.code + "-" + user?.name,
              }))}
              placeholder="Select From Bank"
              isSearchable
              isClearable
            />
          </div>
          {/* {paymentType == 1 && ( */}
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              To Code
            </label>
            {/* select dropdown */}
            {/* <select
                value={toCode}
                onChange={(e) => setToCode(e.target.value)}
                className="input-box"
                id="cars"
                name="cars"
              >
                <option value="">Select To Code</option>
                {userList.map((user) => (
                  <option key={user?.id} value={user?.code}>
                    {user?.code + "-" + user?.name}
                  </option>
                ))}
              </select> */}
            <Select
              // className="input-box"
              value={toCode}
              required
              onChange={async (option) => {
                setToCode(option);
                setToBank("");
                if (!option?.value) return;
                let bankList = await getBanks(option?.value);
                setToBankList(bankList);
              }}
              options={userList.map((user) => ({
                value: user?.code,
                label: user?.code + "-" + user?.name,
              }))}
              placeholder="Select To Code"
              isSearchable
              isClearable
            />
          </div>
          {/* )} */}
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              To Bank
            </label>

            <Select
              // className="input-box"
              value={toBank}
              disabled={!toCode?.value}
              required
              onChange={setToBank}
              options={toBankList.map((user) => ({
                value: user?.code,
                label: user?.code + "-" + user?.name,
              }))}
              placeholder="Select To Bank"
              isSearchable
              isClearable
            />
          </div>
          {paymentType == 2 && label != "Head Office Transaction" ? (
            <div className="mb-2">
              <label htmlFor="" className="mb-10 d-block gray">
                Cash Received By
              </label>

              <Select
                // className="input-box"

                value={recBy}
                onChange={setRecBy}
                options={employeeList.map((user) => ({
                  value: user?.code,
                  label: user?.code + "-" + user?.name,
                }))}
                placeholder="Select Cash Received By"
                isSearchable
                isClearable
              />
            </div>
          ) : (
            ""
          )}
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Amount
            </label>
            <input
              required
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              type="text"
              className="input-box"
              placeholder="Enter Amount"
            />
          </div>

          {/* 3 more fields
            "3 columns missing trns type, ledger head, employee code
Add/edit pop-up
Trans type dropdown: Cash, Bank, Cheque
Site/Employee code: Dropdown
Ledger head: Open input field"
          */}

          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Payment Type
            </label>
            <select
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
              required
              className="input-box"
            >
              <option value="">Select Payment Type</option>
              <option value="1">Payment</option>
              <option value="2">Receiving</option>
            </select>
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Transaction Type
            </label>
            <select
              value={transType}
              onChange={(e) => setTransType(e.target.value)}
              required
              className="input-box"
            >
              <option value="">Select Transaction Type</option>
              <option value="1">Cash</option>
              <option value="2">Bank</option>
              <option value="3">Cheque</option>
            </select>
          </div>
          {transType == 3 ? (
            <div className="mb-2">
              <label htmlFor="" className="mb-10 d-block gray">
                Cheque Number
              </label>
              <input
                value={cheque}
                onChange={(e) => setCheque(e.target.value)}
                required
                type="text"
                className="input-box"
                placeholder="Enter Cheque Number"
              />
            </div>
          ) : (
            ""
          )}
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Purpose Of Payment
            </label>
            <Select
              isDisabled={ledgerHead?.value}
              required
              value={ledgerHead}
              onChange={setLedgerHead}
              options={ledgerList.map((user) => ({
                value: user?.id,
                label: user?.name,
              }))}
              placeholder="Select Purpose Of Payment"
              isSearchable
              isClearable
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="mb-10 d-block gray">
              Site/Employee Code
            </label>

            <Select
              required
              value={site}
              onChange={setSite}
              options={employeeList.map((user) => ({
                value: user?.code,
                label: user?.code + "-" + user?.name,
              }))}
              placeholder="Select Site/Employee Code"
              isSearchable
              isClearable
            />
          </div>
          {showAddCred?.id && (
            <div className="mb-2">
              <label htmlFor="" className="mb-10 d-block gray">
                Any Rectification
              </label>

              <Select
                isDisabled={rectification?.value}
                value={rectification}
                onChange={setRectification}
                options={ledgerList.map((user) => ({
                  value: user?.id,
                  label: user?.name,
                }))}
                placeholder="Select"
                isSearchable
                isClearable
              />
            </div>
          )}

          <div className="mb-2 mt-4">
            <button
              disabled={isUpdatingCreds}
              type="submit"
              className="button button-brown w-100"
            >
              {isUpdatingCreds ? <Loader /> : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddHeadOfficeTransaction;
